
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { FormRulesMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin, BAIconsMixin } from '@/mixins';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import { athleteProfile as athleteProfileStore } from '@/store';
import { HostedVideoDetail } from "types";
import { videoUrlParser, youtubeThumbnailUrl } from "@/helpers";
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';

@Component({
	components: { SubscriptionInfoProvider, VideoThumbnail }
})
export default class ShareProfile extends Mixins(FormRulesMixin, VuetifyMixin, BAIconsMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin) {
	@Prop({ type: Boolean }) show;
	@Prop({ type: Boolean }) large;
	@Prop({ type: Boolean }) small;
	@Prop({ type: Boolean }) noshare;
	@Prop({ type: Boolean }) noActivator;
	@Prop({ type: String }) tooltip: string = undefined;
	@Prop({ default:[]}) emails: string[];

	clicked: boolean = false;
	message: string = "";
	shareButtonText = "SHARE PROFILE";
	showHighlightVideoLinks = false;

	// determine if sharing is available
	isSharingAvailable(numSharesAvailable: number | null)
	{
		if (numSharesAvailable === null) return true;
		if (numSharesAvailable === 0) return false;
		return true;
	}
	isSharingReady(numSharesAvailable: number | null): boolean{
		// if numSharesAvailable is null, it indicates unlimited shares
		if (!numSharesAvailable) return true;

		if (!this.isSharingAvailable(numSharesAvailable)) return false;
		if (this.emails.length > numSharesAvailable) return false;
		return true;
	}
	NumberOfSharesText(numSharesAvailable : number): string {
		if( !numSharesAvailable ) return 'unlimited';
		return numSharesAvailable.toString();
	}
	// update the button message on hover if no shares are available
	onMouseoverShareButton(isSharesAvailable: boolean): void{
		if( !isSharesAvailable ){ this.shareButtonText = "Upgrade Now" }
	}
	onMouseleaveShareButton(): void{
		this.shareButtonText = "Share Profile";
	}

	// tooltip color is 'success' if shares are available
	getTooltipColor(isSharesAvailable: boolean) {
		if( this.noshare ) return "error";
		if( isSharesAvailable ) return "success";
		return "error";
	}	
	get ToolTipText(): string {
		if( !this.tooltip ) this.tooltip = "Share your profile with coaches and scouts";
		if( this.noshare ) this.tooltip = "Upgrade your subscription to view details";
		return this.tooltip;
	}

	// event: share profile button clicked
	onClickShareProfile(show: boolean): void {
		if( this.noshare ) {
			this.$emit('upgrade');
		} else {
		 	this.$emit('update:show', show);
		}
	}

	resetForm(): void{
		this.emails = [];
		this.message = "";
	}
	updateEmail(email: string[]): void{
		this.emails = email
	}
	updateMessage(message: string):void{
		this.message = message;
	}

	get NoActivator(): boolean{
		return this.noActivator;
	}
	get InvalidEmailsMessage(): string | undefined{
		if(this.InvalidEmails.length === 0) return undefined;
		return `Invalid emails: ${this.InvalidEmails.join(', ')}`;
	}
	get InvalidEmails(): string[]{
		return this.emails.filter(email => this.rulesOptionalEmail[0](email) !== true);
	}
	get FormValid(): boolean{
		return this.message.length > 0 && this.InvalidEmails.length === 0 && this.emails.length > 0;
	}

	get Clicked(): Record<string, any> {
		return {
			backgroundColor: this.getColor("baColorGreen2"),
			clicked: this.clicked
		};
	}
	async shareProfile(loadPlanInformation:() =>  Promise<void>): Promise<void>{
		this.$emit("submit", {
			emails: this.emails,
			message: this.message
		});
		this.resetForm();
		
		this.onClickShareProfile(false); // Close the modal
		await loadPlanInformation();
	}

	editHighlightDetails = false;
	originalHighlightDetails: HostedVideoDetail[];
	originalFullMatchDetails: HostedVideoDetail[];
	onCancelEditHighlightDetails() {
		Object.assign(this.athleteProfile.highlightDetails, this.originalHighlightDetails);
		Object.assign(this.athleteProfile.fullmatchDetails, this.originalFullMatchDetails);
		this.editHighlightDetails = false;
	}
	async onSaveHighlightDetails() {
		await athleteProfileStore.patchAthleteProfile({
			patch: {
				id: this.athleteProfile.id, 
				highlightDetails: this.athleteProfile.highlightDetails,
				fullmatchDetails: this.athleteProfile.fullmatchDetails,
			}});
		this.editHighlightDetails = false;
	}
	onEditHighlightDetails() {
		this.originalHighlightDetails = [];
		this.originalFullMatchDetails = [];
		Object.assign(this.originalHighlightDetails, this.athleteProfile.highlightDetails);
		Object.assign(this.originalFullMatchDetails, this.athleteProfile.fullmatchDetails);
		this.editHighlightDetails = true;
	}
	onWatchVideo(url: string) {
		window.open(url, '_blank').focus();
	}
	onAddHighlightVideo() {
		if( !this.athleteProfile.highlightDetails ) this.athleteProfile.highlightDetails = [];
		this.athleteProfile.highlightDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
	}
	onAddFullMatchVideo() {
		if( !this.athleteProfile.fullmatchDetails ) this.athleteProfile.fullmatchDetails = [];
		this.athleteProfile.fullmatchDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
	}
	onInputHighlight(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.highlightDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.highlightDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.highlightDetails[index].label) ) this.athleteProfile.highlightDetails[index].label = videoId;
		this.athleteProfile.highlightDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
	}
	onInputFullMatch(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.fullmatchDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.fullmatchDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.fullmatchDetails[index].label) ) this.athleteProfile.fullmatchDetails[index].label = videoId;
		this.athleteProfile.fullmatchDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
	}
	playVideo(video: HostedVideoDetail) {
		if( !this.isValidUrl(video.videoUrl) ) return;
        window.open( video.videoUrl, "_blank" );
    }
}
